import { Action, Module, VuexModule } from 'vuex-class-modules'
import { Role, UserAuthPayload, UserDetail } from '@/models/dto'
import { Company } from '@/models/dto/Company'

import { clear, load, save } from '@/utils/localStorage'

import auth from '@/services/auth'
import axios from 'axios'
import router from '@/router'
import store from '@/store/index'
import user from '@/services/user'
import transformationSession from '@/services/transformationSession'
import impersonation from './impersonation'
import company from '@/services/company'
import { PartnerTypeId, OnboardingStatusTypeIdTypeId } from '@/utils/enum'
import * as datadog from '../../utils/datadog'

@Module({ generateMutationSetters: true })
class AuthModule extends VuexModule {
  _userId: number | null = load('userId') || null
  _user: UserDetail | null = load('user') || null
  _company: Company | null = load('company') || null
  _token: string | null = load('token') || null
  _isTokenSet = !!load('token')
  _roles: Role[] = load('roles') || []
  _isDriverOnly = load('isDriverOnly') || false
  _isChaperoneOnly = load('isChaperoneOnly') || false
  _isAdmin = load('isAdmin') || false
  _canTransformCompany = load('canTransformCompany') || false
  _isPartner = load('isPartner') || false
  _isOnboarded = load('isOnboarded') || false

  /**
   * Returns the current user.
   * @returns The current user as a `User` object.
   */
  get user(): UserDetail {
    return this._user
  }

  /**
   * Returns the ID of the current user.
   * @returns The ID of the current user as a string.
   */
  get userId(): number | null {
    return this._userId
  }

  /**
   * Returns the current company.
   * @returns The current company as a `Company` object.
   */
  get company(): Company {
    return this._company
  }

  /**
   * Returns the current token.
   * @returns The current token as a string.
   */
  get token(): string {
    return this._token
  }

  /**
   * Returns whether the token is set.
   * @returns A boolean indicating whether the token is set.
   */
  get isTokenSet(): boolean {
    return this._isTokenSet
  }

  /**
   * Returns the user's roles.
   * @returns The user's roles as an array of Role objects.
   */
  get roles(): Role[] {
    return this._roles
  }

  /**
   * Returns the user's full name.
   * @returns The user's full name as a string.
   */
  get fullName(): string {
    if (!this._user) {
      return ''
    }
    return `${this._user.firstName} ${this._user.lastName}`
  }
  /**
   * Returns whether the user is a driver only.
   * @returns A boolean indicating whether the user is a driver only.
   */
  get isDriverOnly(): boolean {
    return this._isDriverOnly
  }
  /**
   * Returns whether the user is a chaperone only (meaning not a admin or user).
   * @returns A boolean indicating whether the user is a chaperone only.
   */
  get isChaperoneOnly(): boolean {
    return this._isChaperoneOnly
  }

  /**
   * Returns whether the user is an admin.
   * @returns A boolean indicating whether the user is an admin.
   */
  get isAdmin(): boolean {
    return this._isAdmin
  }

  /**
   * Returns whether the user can transform the company.
   * @returns A boolean indicating whether the user can transform the company.
   */
  get canTransformCompany(): boolean {
    return this._canTransformCompany
  }

  /**
   * Returns whether the user is a partner.
   * @returns A boolean indicating whether the user is a partner.
   */
  get isPartner(): boolean {
    return this._isPartner
  }

  /**
   * Returns whether the user is a onboarded.
   * @returns A boolean indicating whether the user is a onboarded.
   */
  get isOnboarded(): boolean {
    return this._isOnboarded
  }

  /**
   * Log in the user.
   * Saves the user ID, token, and user object to the local storage.
   * Sets the token as the default authorization header.
   * Redirects to the dashboard page.
   * @param payload The user authentication payload.
   */
  @Action
  async login(payload: UserAuthPayload): Promise<void> {
    const response = await auth.login(payload)
    if (response.data.successful) {
      save('userId', response.data.user.userId)
      save('token', response.data.token)
      this._token = response.data.token
      this._user = response.data.user
      this._userId = response.data.user.userId
      this._isTokenSet = !!response.data.token
      registerBearerToken(response.data.token)

      if(response.data.user) {
        const nameParts = []
        if(response.data.user.firstName) {
          nameParts.push(response.data.user.firstName)
        }
        if(response.data.user.lastName) {
          nameParts.push(response.data.user.lastName)
        }
        datadog.setUserContext("" + response.data.user.userId, response.data.user.email, nameParts.join(" "))
      } else {
        datadog.clearUserContext()
      }
    }
  }

  /**
   * Automatically log in the user.
   */
  @Action
  autoLogin(): void {
    this._user = load('user')
    this._token = load('token')
    this._isTokenSet = !!load('token')
    if (this._token) {
      registerBearerToken(this._token)
    }
  }

  /**
   * Log out the user.
   * Clears the local storage and redirects to the login page.
   * If the user is impersonating another user, the impersonation session is stopped.
   */
  @Action
  logout() {
    if (impersonation.hasTransformationSession) {
      transformationSession.stop()
    }
    clear()
    this._userId = null
    this._user = null
    this._token = null
    this._roles = null
    this._isTokenSet = false
    this._isDriverOnly = false
    this._isChaperoneOnly = false
    this._isAdmin = false
    this._isPartner = false
    this._isOnboarded = false
    datadog.clearUserContext()
    router.push({
      name: 'login',
    })
  }

  /**
   * Get the user's profile.
   * Saves the user's roles to the local storage.
   * Sets the `isDriverOnly` and `isAdmin` properties.
   */
  @Action
  async getUserProfile(): Promise<void> {
    const response = await auth.getUserProfile()
    if (response.data.successful) {
      save('roles', response.data.userProfile.roles)
      this._roles = response.data.userProfile.roles
      const isDriverOnly = checkIsDriverOnly(response.data.userProfile.roles)
      this._isDriverOnly = isDriverOnly
      save('isDriverOnly', isDriverOnly)
      const isChaperoneOnly = checkIsChaperoneOnly(
        response.data.userProfile.roles
      )
      this._isChaperoneOnly = isChaperoneOnly
      save('isChaperoneOnly', isChaperoneOnly)
      const isAdmin = checkIsAdmin(response.data.userProfile.roles)
      this._isAdmin = isAdmin
      save('isAdmin', isAdmin)
      const canTransformCompany = checkCanTransformCompany(
        response.data.userProfile.roles
      )
      this._canTransformCompany = canTransformCompany
      save('canTransformCompany', canTransformCompany)
    }
  }

  /**
   * Get the user's detail.
   * Saves the user object to the local storage.
   */
  @Action
  async getUserDetail(): Promise<void> {
    if (!this._userId) {
      return
    }

    const response = await user.byId(this._userId)

    if (response?.data?.successful) {
      this._user = response.data.user
      save('user', response.data.user)
    }
  }

  /**
   * Get the company's detail.
   * Saves the company object to the local storage.
   */
  @Action
  async getCompanyDetail(): Promise<void> {
    if (!this._user?.companyId) {
      return
    }

    const response = await company.byId(this._user.companyId)

    if (response.status === 200) {
      const company = response.data.company
      const isPartner = checkIsPartner(response.data.company.partnerTypeId)
      const isOnboarded = checkIsOnboarded(
        response.data.company.onboardingStatusTypeId
      )
      this._company = company
      this._isPartner = isPartner
      this._isOnboarded = isOnboarded
      save('company', company)
      save('isPartner', isPartner)
      save('isOnboarded', isOnboarded)
    }
  }

  /**
   * Refresh the user's data.
   * Gets the user's detail and profile.
   */
  @Action
  refreshUser(): void {
    this.registerToken()
    this.getUserDetail()
    this.getUserProfile()
  }

  /**
   * Register the token as the default authorization header.
   */
  @Action
  registerToken() {
    const token = load('token')
    if (token) {
      registerBearerToken(token)
    }
  }
}

//private helpers

/**
 * registers the bearer token as the default authorization header
 */
const registerBearerToken = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

/**
 * checks if the user is a driver only
 * @param roles the user's roles
 * @returns a boolean indicating whether the user is a driver only
 */
const checkIsDriverOnly = (roles: Role[]): boolean => {
  if (roles && roles.length) {
    const isDriver = roles.some((role) => role.roleName === 'is_driver')
    const isAdmin = checkIsAdmin(roles)
    const isUser = roles.some((role) =>
      [
        'is_free_user',
        'is_paid_user',
        'is_broker_user',
        'is_report_admin',
      ].includes(role.roleName)
    )
    return !isAdmin && !isUser && isDriver
  }
  return false
}

/**
 * checks if the user is a chaperone only (not a admin or a user)
 * @param roles the user's roles
 * @returns a boolean indicating whether the user is a chaperone only
 */
const checkIsChaperoneOnly = (roles: Role[]): boolean => {
  if (roles && roles.length) {
    const isChaperone = roles.some((role) => role.roleName === 'is_chaperone')
    const isAdmin = checkIsAdmin(roles)
    const isUser = roles.some((role) =>
      [
        'is_free_user',
        'is_paid_user',
        'is_broker_user',
        'is_report_admin',
      ].includes(role.roleName)
    )
    return !isAdmin && !isUser && isChaperone
  }
  return false
}

/**
 * checks if the user is an admin
 * @param roles the user's roles
 * @returns a boolean indicating whether the user is an admin
 */
const checkIsAdmin = (roles: Role[]): boolean => {
  if (roles && roles.length) {
    return roles.some((role) =>
      [
        'is_free_admin',
        'is_paid_admin',
        'is_broker_admin',
        'is_admin_admin',
      ].includes(role.roleName)
    )
  }
  return false
}

/**
 * checks if the user can transform company
 * @param roles the user's roles
 * @returns a boolean indicating whether the user can transform company
 */
const checkCanTransformCompany = (roles: Role[]): boolean => {
  return roles.some((role) => role.roleName === 'can_transform_all_companies')
}

/**
 * checks if the company is a partner
 * @param partnerTypeId the company's partner type id
 * @returns a boolean indicating whether the company is a partner
 */
const checkIsPartner = (partnerTypeId: number): boolean => {
  if (!partnerTypeId) {
    return false
  }
  return [
    PartnerTypeId.Platinum,
    PartnerTypeId.Gold,
    PartnerTypeId.Silver,
    PartnerTypeId.Bronze,
  ].includes(partnerTypeId)
}

/**
 * checks if the company is onboarded
 * @param onboardingStatusTypeId the company's onboarding status
 * @returns a boolean indicating whether the company has been onboarded or not
 */
const checkIsOnboarded = (onboardingStatusTypeId: number): boolean => {
  if (!onboardingStatusTypeId) {
    return false
  }
  return onboardingStatusTypeId === OnboardingStatusTypeIdTypeId.Onboarded
}

export default new AuthModule({ store, name: 'auth' })
