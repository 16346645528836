import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { clear, load } from '../../utils/localStorage'

export class HttpService {
  private service = axios.create()

  constructor() {
    this.service.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const token = load('token') || null
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    this.service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service.defaults.headers.common['Application-Origin'] = 'cupops'
  }

  private handleSuccess(response: AxiosResponse): AxiosResponse {
    return response
  }

  private handleError = (error: any): Promise<any> => {
    if (
      error?.response?.status === StatusCodes.FORBIDDEN ||
      error?.response?.status === StatusCodes.UNAUTHORIZED
    ) {
      // We are going to clear localstorage, thereby logging out users on 403 errors
      clear()
    } else {
      return Promise.reject(error)
    }
  }

  post<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.post<P, AxiosResponse<T>>(url, payload, config)
  }

  patch<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.patch<P, AxiosResponse<T>>(url, payload, config)
  }

  put<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.put<P, AxiosResponse<T>>(url, payload, config)
  }

  get<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.get<P, AxiosResponse<T>>(url, config)
  }

  delete<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.delete<P, AxiosResponse<T>>(url, config)
  }
}
